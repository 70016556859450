import React from "react";
import Logo from "../assets/logo2.png";
import Log from "../assets/coverdcp2.png";
import "./Home.css";
import 'animate.css';



function Home() {
  return (
    <div >
    <div className="desc">
      <header>
        <div className="logo">
          <img src={Logo} alt="" className="logo" />
        </div>
      </header>
      <div className="hero">
      
        <div className="left-h">
          <div className="hero-text" >
            <div>
              Power your Business
              <br /> with the Digital
              <br />
              Commerce Platform <br />
              (DCP)
            </div>
          </div>
         

          <div className="intro">
            <p class="animate__animated animate__lightSpeedInLeft">
              DCP will help you automate your business operations,accept
              payments, build customer relationships, and grow your business
              in-store and online.
            </p>
          </div>
         
        </div>
        <div className="right-h">
          <div  class="animate__animated animate__bounceInDown">            
        <img src={Log} alt="" className="logee" />
          </div>
        </div>
      </div>
      <div className="butt">
            
            <a href="https://dcp.onl/">
              Check out DCP Platform
            
            </a>
            </div>
            <br/>
            <br/>

    </div>
  

    </div>
  );
}

export default Home;
