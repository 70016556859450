import React from "react";
import "./Foot.css"
function Footer() {
  return (
    <div class="container">
      <div class="row flex-column flex-md-row px-2 justify-content-center">
        <div class="flex-grow-1 col">
          <ul class="block-44__extra-links d-flex list-unstyled p-0">
            <li class="mx-2">
              <a
                href="https://www.facebook.com/cybcorpdigi"
                target="_blank"
                title="Facebook"
                class="block-44__link m-0"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li class="mx-2">
              <a
                href="https://www.instagram.com/cybcorpdigital"
                target="_blank"
                title="Instagram"
                class="block-44__link m-0"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li class="mx-2">
              <a
                href="https://twitter.com/cybcorpdigital"
                target="_blank"
                title="Twitter"
                class="block-44__link m-0"
              >
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li class="mx-2">
              <a
                href="https://www.linkedin.com/in/cybcorp-digital-14bbb8210/?originalSubdomain=ke"
                target="_blank"
                title="Linkedin"
                class="block-44__link m-0" rel="noreferrer"

              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li class="mx-2">
              <a
                href="https://www.youtube.com/c/cybcorpdigital"
                target="_blank"
                title="YouTube"
                class="block-44__link m-0" rel="noreferrer"
              
                
              >
                <i class="fab fa-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
        <p class="block-41__copyrights col col-md-8 text-xxl-end text-xl-end text-lg-end text-md-end text-sm-center">
          © 2023. &nbsp;Cybcorp Limited.&nbsp;<i className='fa fa-heart'> </i> &nbsp;All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
